import { useEffect, useState } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Container, Snackbar } from '@material-ui/core';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Header } from 'components/Header';
import { LoadApp } from 'components/Steps/LoadApp';
import { LendioApp } from 'components/Steps/LendioApp';
import { Error } from 'components/Steps/Error';
import { isValidDbApp } from 'utils/others';
import { appTheme } from 'theme';

export const ROUTES = {
  HOME: '/',
  ERROR: '/error',
  LENDIO_APP: '/forgiveness-account',
};

export const FILE_SIZE_LIMIT = 25;

export const ERRORS = {
  ROUTE_NOT_FOUND: '404 not found',
  GENERAL: 'Something went wrong. Please try again later.',
  FILE_MISSING: 'Please select file',
  FILE_TOO_BIG: `Please make sure file size is less than ${FILE_SIZE_LIMIT}MB`,
  FILE_UPLOAD_FAILED: 'Failed to upload file',
  FAILED_TO_GET_DOCUSIGN_URL: 'Failed to get docusign url',
  COVERED_PERIOD_TO_MISSING: 'Please select "Covered period end date"',
};

const PHONE = {
  LENDIO: '855-207-6251',
  RC: '855-218-9590',
};

const SBA_PORTAL_LINK = 'https://directforgiveness.sba.gov/requests/borrower/login';

export default function App() {
  const location = useLocation();

  const [application, setApplication] = useState();
  const [notification, setNotification] = useState();

  const isLendioApp = application?.redirectToPartner || application?.twoMillionOrMore;
  const isErrorRoute = location?.pathname === ROUTES.ERROR;

  return (
    <>
      <ScrollToTop />

      <StylesProvider injectFirst>
        <ThemeProvider theme={appTheme}>
          <CssBaseline />

          <Container disableGutters={false} style={{ paddingBottom: '3rem' }}>
            {notification && notification.message && (
              <Snackbar
                className={`notification-wrapper--${notification.type}`}
                open={!!notification.message}
                autoHideDuration={6000}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                message={notification.message}
                onClose={() => setNotification(null)}
              />
            )}

            <Header
              phone={getPhone({
                isRc: isErrorRoute,
                isLendio: isLendioApp,
              })}
            />

            {/* <SiteProgress className="mb-lg" /> */}

            <Switch>
              <Route
                exact
                path={ROUTES.HOME}
                render={(routeProps) => {
                  if (isLendioApp) {
                    return <Redirect to={ROUTES.LENDIO_APP} />;
                  }

                  if (isValidDbApp(application)) {
                    window.location.replace(SBA_PORTAL_LINK);

                    return null;
                  }

                  return <LoadApp {...routeProps} initializeApplication={setApplication} />;
                }}
              />
              <Route
                path={ROUTES.LENDIO_APP}
                render={(routeProps) => {
                  if (isLendioApp) {
                    return <LendioApp {...routeProps} application={application} />;
                  }

                  window.location.replace(SBA_PORTAL_LINK);

                  return null;
                }}
              ></Route>
              <Route path={ROUTES.ERROR}>
                <Error />
              </Route>
              <Route path="*">
                <Error message={ERRORS.ROUTE_NOT_FOUND} />
              </Route>
            </Switch>

            <footer className="mt-lg-2 pt pb text-center" style={{ fontSize: '0.9rem' }}>
              For more information on PPP Loan Forgiveness &amp; Terms{' '}
              <a
                href="https://www.sba.gov/funding-programs/loans/covid-19-relief-options/paycheck-protection-program/ppp-loan-forgiveness"
                target="_blank"
                rel="noopener noreferrer"
              >
                click here
              </a>
            </footer>
          </Container>
        </ThemeProvider>
      </StylesProvider>
    </>
  );
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function getPhone({ isRc, isLendio }) {
  if (isRc) {
    return PHONE.RC;
  }

  if (isLendio) {
    return PHONE.LENDIO;
  }

  return '';
}
