import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Container, Typography } from '@material-ui/core';
import { Button } from 'components/_common/Button';
import { ERRORS, ROUTES } from 'App';

export function Error(props) {
  const history = useHistory();
  const message = props?.message || history?.location?.state?.message || ERRORS.GENERAL;

  return (
    <Container style={{ maxWidth: '800px' }}>
      <Typography className="text-center" variant="h2">
        {message}
      </Typography>

      <div className="text-center">
        <Button component={Link} color="secondary" to={ROUTES.HOME}>
          Back to main page
        </Button>
      </div>
    </Container>
  );
}
