import { createMuiTheme } from '@material-ui/core/styles';

const COLORS = {
  TITLE: '#464A53',
  BODY_TEXT: '#6A6A6A',
  BODY_BG: '#f5f5f5',
  PRIMARY: '#168AE1', // RC orange
  PRIMARY_DARKER: '#0f5f9b', // 15%
  PRIMARY_LIGHTER: '#3ea1ec', // 10%
  SECONDARY: '#ff8200',
  SECONDARY_DARKER: '#b35b00',
  SECONDARY_LIGHTER: '#ff9b33',
  WHITE: '#fff',
};

const PSEUDO = {
  FOCUS: '&:focus',
  DISABLED: '&$disabled',
};

export const appTheme = createMuiTheme({
  palette: {
    background: {
      default: COLORS.BODY_BG,
    },
    text: {
      primary: COLORS.BODY_TEXT,
    },
    primary: {
      main: COLORS.PRIMARY,
    },
    secondary: {
      main: COLORS.SECONDARY,
    },
  },
  typography: {
    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
    htmlFontSize: 16,
    fontSize: 16,
    h1: {
      fontSize: '2.25rem',
      fontWeight: 500,
      marginBottom: '1.5rem',
      color: COLORS.TITLE,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 500,
      marginBottom: '1rem',
      color: COLORS.TITLE,
    },
    body1: {
      fontSize: '1rem',
    },
  },
  props: {
    MuiContainer: {
      fixed: true,
      disableGutters: true,
    },
    MuiButton: {
      disableRipple: true,
      disableElevation: true,
      variant: 'contained',
      color: 'primary',
    },
    MuiIconButton: {
      disableRipple: true,
      size: 'small',
      color: 'secondary',
    },
    MuiTextField: {
      size: 'small',
      variant: 'outlined',
    },
    MuiCheckbox: {
      disableRipple: true,
      color: 'primary',
      size: 'small',
    },
    MuiSelect: {
      color: 'primary',
      size: 'small',
      variant: 'outlined',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontSize: '1rem',
        textTransform: 'none',
      },
      label: {
        color: COLORS.WHITE,
      },
      containedPrimary: {
        [PSEUDO.FOCUS]: {
          backgroundColor: COLORS.PRIMARY_DARKER,
        },
        [PSEUDO.DISABLED]: {
          backgroundColor: COLORS.PRIMARY_LIGHTER,
        },
      },
      containedSecondary: {
        color: COLORS.WHITE,
        [PSEUDO.FOCUS]: {
          backgroundColor: COLORS.SECONDARY_DARKER,
        },
        [PSEUDO.DISABLED]: {
          backgroundColor: COLORS.SECONDARY_LIGHTER,
        },
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '1rem',
      },
      input: {
        // textAlign: 'right',
        color: COLORS.TITLE,
        background: COLORS.WHITE,
      },
    },
    MuiFormLabel: {
      root: {
        color: COLORS.WHITE,
      },
    },
    MuiCheckbox: {
      root: {
        color: COLORS.WHITE,
      },
    },
    MuiSelect: {
      root: {
        width: '100%',
      },
      icon: {
        color: 'inherit',
      },
    },
    MuiPickersBasePicker: {
      container: {
        color: 'black',
      },
    },
    MuiPickersDay: {
      day: {
        color: 'inherit',
        fontSize: 14,
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        marginBottom: '12px',
      },
    },
    MuiStepper: {
      root: {
        padding: 0,
        background: 'none',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '0.9rem',
      },
    },
  },
});
