import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { ROUTES } from 'App';
import rcLogo from './img/rc-logo.png';
import './index.css';

export function Header({ className, phone }) {
  return (
    <header className={classNames(className, 'header pt pb')}>
      <Link className="header-link" to={ROUTES.HOME}>
        <img src={rcLogo} alt="" />
      </Link>

      {phone && <span className="header-phone">Call Now: {phone}</span>}
    </header>
  );
}
