import { useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { InfoOutlined as InfoIcon } from '@material-ui/icons';
import classNames from 'classnames';
import './index.css';

export function FormControlInline({ className, label, id, tooltip, children }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={classNames('form-control-inline', className)}>
      <label className="form-control-inline__label" htmlFor={id}>
        <span className="form-control-inline__text">{label}</span>

        <span className="form-control-inline__tooltip">
          {!!tooltip && (
            <Tooltip
              title={tooltip}
              disableFocusListener
              disableTouchListener
              open={isOpen}
              onOpen={() => setIsOpen(true)}
              onClose={() => setIsOpen(false)}
            >
              <IconButton onClick={() => setIsOpen((prevState) => !prevState)}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          )}
        </span>
      </label>

      {children}
    </div>
  );
}
