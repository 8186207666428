import axios from 'axios';
import { MIME_TYPES } from 'components/_common/FileUpload';
import { getStage, STAGE } from 'utils/others';

axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  async (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export function getApplication({ email, lenderPppLoanNumber }) {
  const url = getServiceUrl('/v1/forgiveness/app/get');

  return axios.post(url, { email, lenderPppLoanNumber }).then(handleInternalServiceResponse);
}

export function updateApplication(application) {
  const url = getServiceUrl('/v1/forgiveness/app/update');

  return axios.post(url, application).then(handleInternalServiceResponse);
}

export function uploadFile({ email, lenderPppLoanNumber }, rawFile) {
  const url = getServiceUrl('/v1/forgiveness/app/signed-url');

  const fileContentType = getFileContentType({ name: rawFile?.name, type: rawFile?.type });

  return axios
    .post(url, {
      email,
      lenderPppLoanNumber,
      fileName: rawFile?.name,
      contentType: fileContentType,
    })
    .then(handleInternalServiceResponse)
    .then((data) => {
      const { url, bucket, key } = data || {};

      return axios.put(url, rawFile, { headers: { 'Content-Type': fileContentType } }).then(() => {
        return { bucket, key };
      });
    });
}

export function getSignDocumentUrl({ email, lenderPppLoanNumber }) {
  const url = getServiceUrl('/v1/forgiveness/app/sign-document-url');

  return axios.post(url, { email, lenderPppLoanNumber }).then(handleInternalServiceResponse);
}

function handleInternalServiceResponse(response) {
  if (response?.success) {
    return response.data;
  }

  return null;
}

function getServiceUrl(path) {
  const base = getUrlBase();

  return `${base}${path}`;
}

function getUrlBase() {
  const stage = getStage();

  if (stage === STAGE.LOCAL) {
    return 'http://localhost:3100/dev';
  }

  if (stage === STAGE.DEV) {
    return 'https://ca8rmrvf58.execute-api.us-east-1.amazonaws.com/dev';
  }

  return 'https://4o5wsyji0h.execute-api.us-east-1.amazonaws.com/prod';
}

function getFileContentType({ name, type } = {}) {
  if (type) {
    return type;
  }

  // in some cases type='' on Windows
  // e.g. `text/csv` has type=''
  const ext = getFileExtension(name);

  const extToType = {
    csv: MIME_TYPES.CSV,
    doc: MIME_TYPES.DOC,
    docx: MIME_TYPES.DOCX,
    xls: MIME_TYPES.XLS,
    xlsx: MIME_TYPES.XLSX,
  };

  return extToType[ext] || '';
}

function getFileExtension(name) {
  return name?.split('.')?.pop();
}
