import { useState } from 'react';
import { useHistory } from 'react-router';
import { Container, Paper, TextField, Typography } from '@material-ui/core';
import { Button } from 'components/_common/Button';
import { FormControlInline } from 'components/_common/FormControlInline';
import * as service from 'services/application';
import { isValidDbApp } from 'utils/others';
import { ROUTES } from 'App';
import './index.css';

export function LoadApp({ initializeApplication }) {
  const history = useHistory();

  const [appNumber, setAppNumber] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setLoading] = useState(false);

  function handleSubmit(event) {
    event.preventDefault();

    setLoading(true);
    service
      .getApplication({ email, lenderPppLoanNumber: appNumber })
      .then((application) => {
        setLoading(false);

        if (isValidDbApp(application)) {
          initializeApplication(application);
        } else {
          history.push({
            pathname: ROUTES.ERROR,
            state: {
              message: `Failed to load your application. Please verify that the application number ${appNumber} and email address ${email} are correct.`,
            },
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        history.push(ROUTES.ERROR);
      });
  }

  return (
    <Container style={{ maxWidth: '700px' }}>
      <Typography className="text-center" variant="h1">
        Apply for PPP Loan Forgiveness
      </Typography>

      <Typography className="mb-lg text-center" variant="body1">
        Have you used all the PPP funds you would like to be forgiven?
      </Typography>

      <Container style={{ maxWidth: '650px' }}>
        <Paper className="p-lg" elevation={2}>
          <form className="form-load-app" onSubmit={handleSubmit}>
            <FormControlInline
              className="mb"
              label="Lender application number (APP-1234567)"
              tooltip="Please make sure to use your App Number and not your SBA Loan Number. Ex: APP-1234567"
              id="lender-app-number"
              type="text"
            >
              <TextField
                required
                id="lender-app-number"
                placeholder="APP-1234567"
                inputProps={{
                  'data-test-id': 'app-number',
                }}
                value={appNumber}
                onChange={(event) => setAppNumber(event.target.value.toUpperCase())}
              />
            </FormControlInline>

            <FormControlInline className="mb-lg" label="Your email address" id="email" type="email">
              <TextField
                required
                id="email"
                placeholder="borrower@example.com"
                inputProps={{
                  'data-test-id': 'email',
                }}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </FormControlInline>

            <div>
              <Button
                style={{ minWidth: '150px' }}
                disabled={isLoading}
                showSpinner={isLoading}
                data-test-id="btn-load-app"
                type="submit"
              >
                Get Started
              </Button>
            </div>
          </form>
        </Paper>
      </Container>
    </Container>
  );
}
