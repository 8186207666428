import { Container, Typography } from '@material-ui/core';
import { Button } from 'components/_common/Button';

const LENDIO_PORTAL_URL = 'https://readycapital.loanreliefppp.com/';

export function LendioApp({ application }) {
  return (
    <Container style={{ maxWidth: '700px' }}>
      <Typography className="text-center" variant="h2" component="h1">
        A PPP Loan Forgiveness Account has been set up for you.
      </Typography>

      <Typography className="text-center" style={{ fontSize: '1.1rem' }} variant="body1">
        Please note the following details necessary to login:
      </Typography>

      <ul className="mb-lg">
        <li>
          Your SBA PPP Loan Number:{' '}
          <strong data-test-id="app-sba-loan-number">{application?.sbaPppLoanNumber}</strong>
        </li>
        <li>
          Username: <strong data-test-id="app-email">{application?.email}</strong>
        </li>
        <li>
          Temporary Password: last 4 digits of your SBA PPP Loan Number + last 4 digits of your
          EIN/SSN
        </li>
      </ul>

      <div className="text-center">
        <Button href={LENDIO_PORTAL_URL}>Continue</Button>
      </div>
    </Container>
  );
}
