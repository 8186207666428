import { formatDate, DATE_UI_FORMAT } from 'utils/dates';

export const STAGE = {
  DEV: 'dev',
  PROD: 'prod',
  LOCAL: 'local',
};

export function isValidDbApp(app) {
  return app?.email && app?.lenderPppLoanNumber ? true : false;
}

export function financial(x) {
  return Number.parseFloat(x || 0).toFixed(2);
}

export function toDollarFormat(str) {
  if (!str) {
    return '';
  }

  return Number(str).toLocaleString('en-us', { style: 'currency', currency: 'USD' });
}

export function toDateUi(date) {
  if (!date) {
    return '';
  }

  return formatDate(date, DATE_UI_FORMAT);
}

export function getStage() {
  return process.env.REACT_APP_STAGE;
}

export function getMissingDocsLink(appId) {
  const stage = getStage();

  if (stage === STAGE.PROD) {
    return `https://upload.readycapital.com/?id=${appId}`;
  }

  return `https://dev--missing-docs.netlify.app/?brand=rc&id=${appId}`;
}
