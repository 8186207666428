import { Button as MuiButton, CircularProgress } from '@material-ui/core';

export function Button({
  className,
  style,
  type,
  disabled,
  showSpinner,
  spinnerSize = 28,
  children,
  ...restProps
}) {
  return (
    <MuiButton className={className} style={style} disabled={disabled} type={type} {...restProps}>
      {showSpinner ? <CircularProgress size={spinnerSize} style={{ color: 'white' }} /> : children}
    </MuiButton>
  );
}
